export type FormField = {
  name: string;
  label?: string;
  type:
    | 'text'
    | 'name'
    | 'sex'
    | 'date'
    | 'nameWithSuffix'
    | 'select'
    | 'search'
    | 'switchbutton'
    | 'horseInfo'
    | 'personInfo'
    | 'multiplePersons';
  options?: string[];
  defaultValue?: string;
  sx?: object;
  hidden?: string | boolean;
  description?: string;
  notRequired?: boolean;
};

export const fields: FormField[] = [
  {
    name: 'fullName',
    label: 'Namn/Bolag',
    type: 'text',
    defaultValue:
      'userData.basicUserInformation.firstName + " " + userData.basicUserInformation.lastName',
  },
  {
    name: 'firstName',
    label: 'Förnamn',
    type: 'text',
    defaultValue: 'userData.basicUserInformation.firstName',
  },
  {
    name: 'lastName',
    label: 'Efternamn',
    type: 'text',
    defaultValue: 'userData.basicUserInformation.lastName',
  },
  {
    name: 'email',
    label: 'E-post',
    type: 'text',
    defaultValue: 'userData.changeableUserInformation.email',
    sx: { gridColumn: 'span 2' },
  },
  {
    name: 'emailInvoice',
    label: 'E-post för fakturor',
    type: 'text',
  },
  {
    name: 'multiplePersons',
    label: 'Flera personer',
    type: 'multiplePersons',
  },
  {
    name: 'personalNumber',
    label: 'Personnummer/Organisationsnummer',
    type: 'text',
    defaultValue: 'user.userInfo.idNumber',
  },
  {
    name: 'zipCodeCity',
    label: 'Postnummer och postort',
    type: 'text',
    defaultValue: 'userData.basicUserInformation.address.postAddress',
  },
  {
    name: 'address',
    label: 'Gatuadress',
    type: 'text',
    defaultValue: 'userData.basicUserInformation.address.streetAddress',
  },
  {
    name: 'horseSwitchButton',
    label: 'Häst',
    type: 'switchbutton',
    options: ['Registrerad häst', 'Ej registrerad häst'],
  },
  {
    name: 'horseSwitchButtonStallion',
    label: 'Häst',
    type: 'switchbutton',
    options: ['Registrerad häst', 'Ej registrerad häst'],
  },
  {
    name: 'horseSwitchButtonTraining',
    label: 'Häst',
    type: 'switchbutton',
    options: ['Anmäla häst i träning', 'Ta häst ur träning'],
  },
  {
    name: 'personSwitchButton',
    label: 'Fysisk eller juridisk person',
    type: 'switchbutton',
    options: ['Fysisk person', 'Juridisk person'],
  },
  {
    name: 'horse',
    label: 'Häst',
    type: 'select',
    options: [
      'ownedHorses',
      'registrationStatusChangeable',
      'userRepresentative',
    ],
  },
  {
    name: 'horseTraining',
    label: 'Häst',
    type: 'select',
  },
  {
    name: 'mare',
    label: 'Sto',
    type: 'select',
    options: [
      'ownedHorses',
      'registrationStatusChangeable',
      'userRepresentative',
      'mare',
    ],
  },
  {
    name: 'stallion',
    label: 'Hingst',
    type: 'select',
    options: [
      'ownedHorses',
      'registrationStatusChangeable',
      'userRepresentative',
      'stallion',
    ],
  },
  {
    name: 'customerNumber',
    label: 'Kundnummer',
    type: 'text',
    defaultValue: 'user.licenseId',
  },
  {
    name: 'juridicalPersonName',
    label: 'Bolagsnamn',
    type: 'text',
  },

  {
    name: 'juridicalPersonOrgNumber',
    label: 'Organisationsnummer',
    type: 'text',
  },
  {
    name: 'juridicalPersonAddress',
    label: 'Gatuadress',
    type: 'text',
  },
  {
    name: 'juridicalPersonZipCode',
    label: 'Postnummer',
    type: 'text',
  },
  {
    name: 'juridicalPersonCity',
    label: 'Postort',
    type: 'text',
  },
  {
    name: 'juridicalPersonPhone',
    label: 'Telefon',
    type: 'text',
    notRequired: true,
  },
  {
    name: 'juridicalPersonEmail',
    label: 'E-post',
    type: 'text',
  },
  {
    name: 'horseInfo',
    type: 'horseInfo',
  },
  {
    name: 'mareInfo',
    type: 'horseInfo',
  },
  {
    name: 'stallionInfo',
    type: 'horseInfo',
  },
  {
    name: 'horseSearchInfo',
    type: 'horseInfo',
  },
  {
    name: 'actorId',
    label: 'Person',
    type: 'text',
    defaultValue: 'user.licenseId',
  },
  {
    name: 'personInfo',
    type: 'personInfo',
  },
  {
    name: 'horseName',
    label: 'Hästnamn',
    type: 'nameWithSuffix',
    description: 'NN om ej namngiven',
  },
  {
    name: 'stallionName',
    label: 'Hästnamn',
    type: 'nameWithSuffix',
  },
  {
    name: 'horseBirthYear',
    label: 'Födelseår',
    type: 'text',
  },
  {
    name: 'stallionBirthYear',
    label: 'Födelseår',
    type: 'text',
  },
  {
    name: 'horseSex',
    label: 'Kön',
    type: 'sex',
  },
  {
    name: 'horseFather',
    label: 'Far',
    type: 'text',
  },
  {
    name: 'stallionFather',
    label: 'Far',
    type: 'text',
  },
  {
    name: 'horseMother',
    label: 'Mor',
    type: 'text',
  },
  {
    name: 'stallionMother',
    label: 'Mor',
    type: 'text',
  },
  {
    name: 'horseGrandFather',
    label: 'Morfar',
    type: 'text',
  },
  {
    name: 'stallionGrandFather',
    label: 'Morfar',
    type: 'text',
  },
  {
    name: 'mare',
    label: 'Sto',
    type: 'text',
  },
  {
    name: 'country',
    label: 'Land stoet importerats från',
    type: 'text',
  },
  {
    name: 'stallion',
    label: 'Hingst',
    type: 'text',
  },
  {
    name: 'horseName1',
    label: 'Namnförslag 1',
    type: 'name',
  },
  {
    name: 'horseName2',
    label: 'Namnförslag 2',
    type: 'name',
    notRequired: true,
  },
  {
    name: 'horseName3',
    label: 'Namnförslag 3',
    type: 'name',
    notRequired: true,
  },
  {
    name: 'accountTypeSwitchButton',
    label: 'Konto',
    type: 'switchbutton',
    options: ['Bankkonto', 'Personkonto'],
  },
  {
    name: 'accountNumber',
    label: 'Kontonummer',
    type: 'text',
  },
  {
    name: 'accountNumberBankgiro',
    label: 'Kontonummer (Bankgiro)',
    type: 'text',
  },
  {
    name: 'clearingNumber',
    label: 'Clearingnummer',
    type: 'text',
  },
  {
    name: 'accountNumberPerson',
    label: 'Kontonummer (personkonto)',
    type: 'text',
  },
  {
    name: 'bankName',
    label: 'Bank',
    type: 'text',
  },
  {
    name: 'importCountry',
    label: 'Importland',
    type: 'text',
  },
  {
    name: 'stallingCountry',
    label: 'Uppstallningsland',
    type: 'text',
  },
  {
    name: 'stallionSearch',
    label: 'Hingst',
    type: 'search',
    options: ['horse', 'stallions'],
  },
  {
    name: 'horseSearch',
    label: 'Häst',
    type: 'search',
    options: ['horse'],
  },
  {
    name: 'horseInTraining',
    label: 'Hästen i träning fr.o.m.',
    type: 'date',
  },
  {
    name: 'horseOutOfTraining',
    label: 'Hästen i träning t.o.m.',
    type: 'date',
  },
  {
    name: 'horseLocation',
    label: 'Hästens vistelseort',
    type: 'text',
  },
  {
    name: 'bidragYear',
    label: 'År då stoet importeras',
    type: 'text',
    defaultValue: 'new Date().getFullYear()',
  },
];
